























































































































































































import { Business, Role, User } from "@/types";
import Vue, { PropType } from "vue";
export default Vue.extend<any, any, any, any>({
  name: "LocationForm",
  props: {
    location: {
      type: Object as PropType<{ name: string; zip: string }>,
      required: true,
    },
  },
  data: () => ({
    address: "",
    serviceType: "",
    phone: "",
    email: "",
    serviceTypes: [
      "Beauty",
      "Travel",
      "Activities",
      "Wellness",
      "Hair",
      "Barber",
      "Fitness",
      "General Services",
      "Medi-Aesthetics",
      "Event Planning",
      "Coach Services",
    ],
    monday: {
      from: "",
      to: "",
    },
    tuesday: {
      from: "",
      to: "",
    },
    wednesday: {
      from: "",
      to: "",
    },
    thursday: {
      from: "",
      to: "",
    },
    friday: {
      from: "",
      to: "",
    },
    saturday: {
      from: "",
      to: "",
    },
    sunday: {
      from: "",
      to: "",
    },
    contact: {
      companyName: "",
      phone: "",
      address: "",
      email: "",
    },
    invoiceAppear: true,
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.address = this.location.name;
    this.contact.address = this.location.name;
    if (this.role) {
      const business = this.role.business as Business;
      const user = this.role.user as User;
      this.contact.companyName = business.name;
      this.serviceType = business.type;
      this.email = user.email;
      this.contact.email = user.email;
    }
  },
  methods: {
    validateForm() {
      const valid = (
        this.$refs.shopForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      this.$emit("data-validated", {
        serviceType: this.serviceType,
        phone: this.phone,
        email: this.email,
        workHours: {
          monday: this.monday,
          tuesday: this.tuesday,
          wednesday: this.wednesday,
          thursday: this.thursday,
          friday: this.friday,
          saturday: this.saturday,
          sunday: this.sunday,
        },
        contact: this.contact,
      });
    },
    applyAll(apply: boolean) {
      if (apply) {
        this.tuesday = this.monday;
        this.wednesday = this.monday;
        this.thursday = this.monday;
        this.friday = this.monday;
        this.saturday = this.monday;
        this.sunday = this.monday;
      }
    },
  },
});
