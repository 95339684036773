



























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import ShopAccountForm from "@/components/shop/ShopAccountForm.vue";
import ShopLocationForm from "@/components/shop/ShopLocationForm.vue";
import shopStoreModule from "@/store/modules/shop";
import shopWorkHoursStoreModule from "@/store/modules/shopWorkHours";
import { Business, Role, Shop, ShopWorkHours, User } from "@/types";

const { mapActions: shopActions } = createNamespacedHelpers("SHOP_");

const { mapActions: showWokhoursActions } =
  createNamespacedHelpers("SHOP_WORK_HOURS");

export default Vue.extend<any, any, any, any>({
  name: "CreateShop",
  components: {
    ShopAccountForm,
    ShopLocationForm,
  },
  data() {
    return {
      e1: 1,
      steps: 2,
      shop: undefined as undefined | Shop,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  computed: {
    user(): User {
      return this.$store.getters.user;
    },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...shopActions(["createShop"]),
    ...showWokhoursActions(["createShopWorkHours"]),
    nextStep(n: number) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    setAccount(shop: Shop) {
      this.shop = { ...this.shop, ...shop };
      this.e1 = 2;
    },
    saveShop(locationData: {
      serviceType: string;
      location: { name: string; lat: number; lng: number };
      workHours: ShopWorkHours;
      contact: {
        companyName: string;
        phone: string;
        address: string;
        email: string;
      };
      phone: string;
      email: string;
    }) {
      const data = new FormData();
      const shop = this.shop as Shop;

      data.append("businessId", (this.role.business as Business)._id);
      data.append("name", shop.name);
      data.append("owner", this.user.displayName);
      // data.append("ownerIdNo", this.user.);
      data.append("area", locationData.location.name);
      data.append("latitude", `${locationData.location.lat}`);
      data.append("longitude", `${locationData.location.lng}`);
      data.append("description", shop.description);
      data.append("timezone", shop.timezone);
      data.append("timeFormat", shop.timeFormat);
      data.append("weekStart", shop.weekStart);
      data.append(
        "clientNotificationLanguage",
        shop.clientNotificationLanguage
      );
      if (shop.instagramLink) data.append("instagramLink", shop.instagramLink);
      if (shop.facebookLink) data.append("facebookLink", shop.facebookLink);
      if (shop.website) data.append("website", shop.website);
      data.append("contactPhone", locationData.phone);
      data.append("contactEmail", locationData.email);
      data.append("serviceType", locationData.serviceType);
      data.append("billingCompanyName", locationData.contact.companyName);
      data.append("billingPhone", locationData.contact.phone);
      data.append("billingEmail", locationData.contact.email);
      data.append("billingAddress", locationData.contact.address);

      if (shop.images.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shop.images.map((image: any) => data.append("file", image));
      }

      for (const k in locationData.workHours) {
        if (
          locationData.workHours[k as "sunday"]?.from === "" ||
          locationData.workHours[k as "sunday"]?.to === ""
        )
          delete locationData.workHours[k as "sunday"];
      }

      this.createShop(data).then((shop) => {
        if (shop) {
          this.createShopWorkHours({
            shopId: shop._id,
            ...locationData.workHours,
          }).then((workHours) => {
            localStorage.setItem("shopId", shop._id);
            if (workHours) this.$router.push({ path: "/shop" });
          });
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_")) {
      this.$store.registerModule("SHOP_", shopStoreModule);
    }
    if (!this.$store.hasModule("SHOP_WORK_HOURS")) {
      this.$store.registerModule("SHOP_WORK_HOURS", shopWorkHoursStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_");
    this.$store.unregisterModule("SHOP_WORK_HOURS");
  },
});
