


































































































































































































import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ShopAccountForm",
  data: () => ({
    name: "",
    description: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeFormat: "24Hr",
    weekStart: "Monday",
    active: true,
    clientNotificationLanguage: "English",
    images: [] as File[],
    website: "",
    facebook: "",
    instagram: "",
    nameRules: [(v: string) => !!v || "Name field is required"],
    timezoneRules: [(v: string) => !!v || "Timezone field is required"],
  }),
  methods: {
    validateForm() {
      const valid = (
        this.$refs.shopForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.$emit("next", {
        name: this.name,
        description: this.description,
        timezone: this.timezone,
        timeFormat: this.timeFormat,
        weekStart: this.weekStart,
        clientNotificationLanguage: this.clientNotificationLanguage,
        images: this.images,
        website: this.website || null,
        facebookLink: this.facebook
          ? "https://facebook.com/" + this.facebook
          : null,
        instagramLink: this.instagram
          ? "https://instagram.com/" + this.instagram
          : null,
      });
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.images.push(file);
    },
  },
});
