































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Client } from "@googlemaps/google-maps-services-js";

import locationStoreModule from "@/store/modules/location";
import nearbySearchStoreModule from "@/store/modules/placeSearch";
import {
  Business,
  Role,
  ShopWorkHours,
  PlaceSearchFromTextResult,
} from "@/types";
import LocationForm from "./LocationForm.vue";

const { mapActions: placeSearchActions } =
  createNamespacedHelpers("LOCATION_SEARCH");

const { mapActions: locationActions } =
  createNamespacedHelpers("SHOP_LOCATION");

// const axiosInstance = axios.create({});
const client = new Client({
  /*axiosInstance: axiosInstance*/
});

const KEY = process.env.VUE_APP_GOOGLE_GEOCODING_API_KEY as string;

const getLatLon = async (place: string) => {
  return await client.geocode({
    params: {
      key: KEY,
      address: place,
    },
  });
};

export default Vue.extend<any, any, any, any>({
  components: { LocationForm },
  name: "ShopLocationForm",
  data: () => ({
    showLocationForm: false,
    location: undefined as undefined | { name: string; zip: string },
    locations: [] as { name: string; selected: boolean; zip: string }[],
    showLocationDialog: false,
    addedLocation: "",
    latlng: "",
    searchedLocations: [] as string[],
    locationRules: [(v: string) => !!v || "Location field is required"],
    lat: undefined as undefined | number,
    lng: undefined as undefined | number,
    locationName: "",
    customLocation: "",
    isLoadingLocations: false,
    valid: false,
  }),
  watch: {
    role() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs;
          }
        });
      }
    },
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...locationActions(["fetchLocationList", "createLocation"]),
    ...placeSearchActions(["searchFromText"]),
    searchLocation(q: string) {
      this.customLocation = q;
      if (q) {
        this.isLoadingLocations = true;
        let params = `?text=${q}`;
        if (this.latlng) params = params + `&latlng=${this.latlng}`;
        this.searchFromText(params).then((res: PlaceSearchFromTextResult) => {
          this.isLoadingLocations = false;
          if (res) {
            this.searchedLocations = res.candidates.map(
              (c) => c.formatted_address
            );
          }
        });
      }
    },
    addLocation() {
      const valid = (
        this.$refs.locationForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.locations.push({
        name: this.addedLocation,
        zip: "",
        selected: false,
      });
      this.setLocation({ name: this.addedLocation, zip: "" });
    },
    async saveLocation() {
      if (!this.valid) return;

      const res = await getLatLon(this.locationName);
      if (res.data.status === "ZERO_RESULTS") {
        this.$store.dispatch("setToast", {
          // title: "Location Invalid",
          type: "error",
          text: "Please pick a different location!",
        });
        return;
      }
      const latitude = res.data.results[0]?.geometry.location.lat;
      const longitude = res.data.results[0]?.geometry.location.lng;

      this.createLocation({
        name: this.locationName,
        businessId: (this.role.business as Business)._id,
        area: this.locationName,
        latitude,
        longitude,
      }).then((title) => {
        if (title) {
          this.showLocationDialog = false;
          this.fetchLocationList();
        }
      });
    },
    saveData(data: { serviceType: string; workHours: ShopWorkHours }) {
      this.$emit("save-shop", {
        ...data,
        ...{
          location: {
            lat: this.lat,
            lng: this.lng,
            name: this.location?.name,
          },
        },
      });
    },
    async setLocation(l: { name: string; zip: string }) {
      const res = await getLatLon(l.name);
      if (res.data.status === "ZERO_RESULTS") {
        this.$store.dispatch("setToast", {
          // title: "Location Invalid",
          type: "error",
          text: "Please pick a different location!",
        });
        return;
      }
      this.lat = res.data.results[0]?.geometry.location.lat;
      this.lng = res.data.results[0]?.geometry.location.lng;

      this.location = l;

      if (this.location) {
        this.showLocationDialog = false;
        this.showLocationForm = true;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_LOCATION")) {
      this.$store.registerModule("SHOP_LOCATION", locationStoreModule);
    }
    if (!this.$store.hasModule("LOCATION_SEARCH")) {
      this.$store.registerModule("LOCATION_SEARCH", nearbySearchStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_LOCATION");
    this.$store.unregisterModule("LOCATION_SEARCH");
  },
});
